* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
}

.App {
  font-family: sans-serif;
  width: 100%;
  height: 100vh;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}
