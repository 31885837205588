.outputNode {
  border: 1px solid  #3d8a22;
}

.handle {
  height: 100%;
  border-radius: 0;
  border: medium none;
  width: 20px;
  left: -20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #3d8a22;
}

.body {
  display: flex;
  flex-direction: column;
  outline: 1px solid #3d8a22;
  padding: 8px;
}

.label {
  color: gray;
  margin-bottom: 4px;
}

.select {
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 4px;
  background-color: #f5f5f5;
}

.select:hover, .select:hover:focus {
  cursor: pointer;
}

/*
.handle:hover {
  background-color: #FF4696 !important
}
*/
