.sidebar {
  padding: 15px 10px;
  font-size: 12px;
}

.description {
  margin-bottom: 10px;
}

.sidebar h2 {
  margin-bottom: 10px;
}

.actionNodes,
.logicNodes,
.outputNodes {
  margin-bottom: 10px;
}

.actionNode,
.logicNode,
.outputNode {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  margin-bottom: 10px;
  height: 30px;
}

.actionNode:nth-last-of-type(1),
.logicNode:nth-last-of-type(1),
.outuptNode:nth-last-of-type(1) {
  margin-bottom: 20px;
}
