.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  min-height: 30px;
}

.labelContainer {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.draggerIcon {
  width: 24px;
  height: 24px;
}

.close {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.close:hover {
  fill: rgba(0, 0, 0, .5);
}

.close:focus {
  outline: 0;
}

.closeIcon {
  width: 24px;
  height: 24px;
}
